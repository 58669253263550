/* Defult */
.App {
  text-align: center;

  .main-container {
    min-height: 80vh;
    background-color: #E5E4E2;
  }
}

/* Css for header */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #333;
  color: #fff;
  left: 0;
  position: sticky;
  z-index: 1000;
  top: 0;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
}

.logo-dec {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}

.logo1 {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.logo1 img {
  width: 100%;
  height: auto;
}

.header-options:hover {
  background: none;
  color: cornflowerblue;
}

.buttons {
  display: flex;
  align-items: center;
  flex-grow: 1;
  /* Take up remaining space */
  justify-content: flex-end;
  /* Align buttons to the right */
}

.buttons.expanded {
  flex-direction: column;
  /* Stack buttons vertically when expanded */
}

.buttons a {
  text-decoration: none;
  /* Remove underline from button links */
}

.buttons a:not(:last-child) {
  margin-right: 10px;
  /* Add spacing between buttons */
}

button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 20px;
  transition: background-color 0.3s, font-size 0.3s, font-weight 0.3s;
  color: white;
}

button:hover {
  background-color: #555;
  font-size: 20px;
  font-weight: bold;
}

.hamburger {
  display: none;
  /* Initially hide for larger screens */
  cursor: pointer;
}

/* Media query to show hamburger for smaller screens */
@media screen and (max-width: 1023px) {
  .hamburger {
    display: block;
    /* Show for smaller screens */
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
  }

  .hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
  }

  /* Hamburger animation */
  .hamburger.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .buttons {
    display: none;
    /* Hide navigation buttons by default */
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #333;
    flex-direction: column;
  }

  .buttons.expanded {
    display: flex;
    /* Show navigation buttons when expanded */
  }

  .buttons a {
    text-align: center;
    padding: 15px 0;
  }
}

.key-persons {
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .logo1 {
    width: 35px;
    height: 45px;
  }

  .logo {
    font-size: 20px;
  }

  .leftDiv {
    margin-bottom: 5vh !important;
  }

  .leftDivAbout {
    width: 80% !important;
    padding-left: 30px !important;
    padding-right: 2vh !important;
    font-weight: normal !important;
  }

  .rightDivAbout {
    width: 80% !important;
    padding-right: 30px !important;
    padding-left: 2vh !important;
    font-weight: normal !important;
  }

  .key-persons {
    display: block !important;
  }

  .form-align {
    display: block !important;

    .form-label {
      text-align: start !important;
    }
  }

  .checkbox-row {
    width: 100% !important;
  }

  .styled-list {
    margin-left: 0 !important;
    width: 100% !important;
    line-height: 0 !important;
  }

  .popup-content {
    width: 80% !important;
    height: auto !important;
    margin: 12vh 3vh 0 3vh !important;
  }

  .contentFooter {
    width: 100% !important;
    padding: 0 !important;
    line-height: 0 !important;
  }
}


/* Footer CSS */

.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.left-text,
.right-text {
  padding: 0 25px;
  text-align: center;
  margin: 10px 0;
}

.left-text {
  order: 1;
  /* Change the order on small screens */
}

.right-text {
  order: 2;
  /* Change the order on small screens */
}

@media screen and (min-width: 768px) {
  .footer-content {
    flex-direction: row;
  }

  .left-text,
  .right-text {
    width: 50%;
    /* Make each section take up half the space on larger screens */
    text-align: left;
    margin: 0;
    order: 0;
    /* Reset the order */
  }

  .right-text {
    text-align: right;
  }
}

.term:hover,
.privacy:hover {
  cursor: pointer;
}

.left-para {
  padding: 0;
}

.custom-popup {
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 20px;
}

.custom-close-button {
  background-color: #007bff;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.contentFooter {
  padding: 3vh 10vh;
  text-align: justify;
  line-height: 1.5;
}

.popup-content {
  border-radius: 10px;
}



/* ContactForm.css */

.container {
  border-radius: 5px;
  padding: 20px;
}

.form-design {
  width: 70%;
  margin: auto;
  border-width: thick;
  border-color: black;
  border: solid;
  padding: 2%;
  border-radius: 3vh;
  background-color: gainsboro;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

label {
  padding: 12px 15px 12px 0;
  display: inline-block;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.row label {
  flex: 1;
  padding-right: 10px;
}

.row .col-75 {
  flex: 2;
}

.row-submit {
  text-align: center;
  padding-top: 20px;
}

.submit {
  font-size: 15px;
}

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 100px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
  text-align: right;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.col-20 {
  width: 20px;
  margin-top: -10px;
  text-align: right;
  margin-left: 30vh;
}

.col-70 {
  width: 75%;
  margin-top: 6px;
}

.checkbox-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .form-design {
    width: 90%;
  }

  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }

  .row label {
    padding: 0;
    margin-bottom: 5px;
  }
}






/* About Page CSS */

.text-gray-600 {
  color: #4a5568;
}

.body-font {
  font-family: 'Roboto', sans-serif;
}

.containerAbout {
  /* width: 100%; */
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: nowrap;
}

.-m-4 {
  margin: -1rem;
}

.lg\:w-1\/3 {
  width: 33.333333%;
}

.lg\:mb-0 {
  margin-bottom: 0;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.h-full {
  height: 100%;
}

.text-center {
  text-align: center;
}

.w-20 {
  width: 5rem;
}

.h-20 {
  height: 5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.rounded-full {
  border-radius: 9999px;
}

.inline-block {
  display: inline-block;
}

.border-2 {
  border-width: 2px;
}

.border-gray-200 {
  border-color: #e2e8f0;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.leading-relaxed {
  line-height: 1.625;
}

.inline-block {
  display: inline-block;
}

.h-1 {
  height: 0.25rem;
}

.w-10 {
  width: 2.5rem;
}

.rounded {
  border-radius: 0.25rem;
}

.bg-indigo-500 {
  background-color: #667eea;
}

.mt-6 {
  margin-top: 1.5rem;
}

.title-font {
  font-family: 'Montserrat', sans-serif;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.text-sm {
  font-size: 0.875rem;
}

.text-gray-900 {
  color: #1a202c;
}

.font-medium {
  font-weight: 500;
}

.text-gray-500 {
  color: #718096;
}

p {
  margin-bottom: 1.25rem;
}


@media (max-width: 768px) {
  .lg\:w-1\/3 {
    flex: 0 0 100%;
  }
}

.outerDiv {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}


.leftDivAbout {
  text-align: left;
  width: 70%;
  padding-left: 70px;
  line-height: 2;
  border-width: thick;
  border-color: black;
  border: solid;
  border-radius: 0vh 32vw 8vh 0vh;
  padding-right: 18vh;
  background-color: lavenderblush;
  font-weight: 800;
}

.rightDivAbout {
  margin-left: auto;
  text-align: end;
  padding-right: 70px;
  width: 70%;
  line-height: 2;
  border-width: thick;
  border-color: black;
  border: solid;
  border-radius: 32vw 0 0 8vh;
  padding-left: 18vh;
  background-color: lavender;
  font-weight: 800;
}

.rightDivAboutHeading {
  text-align: right;
}

.styled-list {
  color: #333;
  font-size: 16px;
  margin-left: 40px;
  width: 30%;
  line-height: 2;
}

.styled-list li {
  margin-bottom: 12px;
  text-align: left;
  cursor: pointer;
}

.info-box {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 8px;
  margin-right: 120px;
  margin-bottom: 30px;
  width: 50%;
  border-radius: box;
}

/* Add your existing CSS here */

@media (max-width: 768px) {
  .containerAbout .flex-wrap {
    flex-direction: column;
    align-items: center;
  }

  .containerAbout .flex-wrap .w-full {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .containerAbout .flex-wrap .lg:w-1\/3 {
    width: 100%;
  }
}



/* Updated CSS for FirstPage or Home */
.above {
  padding: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  background-color: #2c3e50;
  border-radius: 10px;
  transition: transform 0.3s ease;
  /* Transition for scaling */
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: none;
  /* Remove box shadow */
}


.above:active {
  transform: scale(0.95);
  /* Scale down by 5% when clicked */
  opacity: 0.6;
  /* Reduce opacity when clicked */
}

@media (max-width: 768px) {
  .above {
    font-size: 12px;
    /* Decrease font size */
    padding: 10px;
    /* Decrease padding */
    width: 80%;
    /* Decrease width */
  }
}


.containerF {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.containerC {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: none;
  /* Remove border */
  background-color: transparent;
  max-width: 600px;
  /* Set wider max-width */
  margin: 0 auto;
  /* Center the div */
}

.leftDiv,
.rightDiv,
.centerDiv {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  border: 5px solid #ccc;
  border-radius: 15px;
  background-color: white;
}

.centerDiv {
  text-align: center;
  border: none;
  /* Remove border */
  background-color: none;
  background-color: transparent;
  max-width: 600px;
  /* Set wider max-width */
  margin: 0 auto;
  /* Center the div */
}

.centerDiv ul {
  list-style-type: decimal;
  padding: 0;
}

.centerDiv li {
  margin-bottom: 10px;
  font-style: italic;
}

.slick-slider {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.slick-list {
  margin: 0;
  padding: 0;
}

.slick-slide img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 0 0 15px 0;
  transition: transform 0.3s ease-in-out;
}

.slick-slide:last-child img {
  margin-right: 0;
}

.slick-slide img:hover {
  transform: scale(1.05);
}

/* Remove the media queries for imageVision and imageMission */
.imageVision,
.imageMission {
  width: 220px;
  height: 135px;
  margin: 0 auto 10px;
}

.checkbox-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.responsive-image {
  width: 100%;
  height: auto;
  /* Set height to auto to maintain aspect ratio */
  max-height: 300px;
  /* Maximum height */
  object-fit: cover;
  border-radius: 20px;
}

/* For screens up to 425px width */
@media only screen and (max-width: 425px) {
  .responsive-image {
    max-height: 150px;
  }
}

/* For screens from 426px to 720px width */
@media only screen and (min-width: 426px) and (max-width: 720px) {
  .responsive-image {
    max-height: 200px;
  }
}

/* For screens from 721px to 1024px width */
@media only screen and (min-width: 721px) and (max-width: 1024px) {
  .responsive-image {
    max-height: 300px;
  }
}

/* For screens larger than 1024px width */
@media only screen and (min-width: 1025px) {
  .responsive-image {
    max-height: 300px;
  }
}